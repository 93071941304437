import React, {useEffect} from 'react';
import {EmailIcon, LinkedinIcon, TwitterIcon} from "../icons";
import {isMobile} from "react-device-detect";

const AuthorDetails = ({author, customClass, deferImage}) => {
    useEffect(() => {
        if(!deferImage) {
            return;
        }
        const timeout = setTimeout(() => {
            const img = document.getElementById('authorDetailsImage');
            if (img && img.getAttribute('data-src')) {
                img.setAttribute('src', img.getAttribute('data-src'));
            }
        }, 500 * (isMobile ? 4 : 1));
        return () => {
            clearTimeout(timeout);
        }
    }, [deferImage]);

    return (
        <div className={`author-card author-card--big ${customClass ? customClass : ''}`}>
            <div className="author-card__image">
                {deferImage && <img id={"authorDetailsImage"} width={128} height={128} alt={author.name}
                                          data-src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto.url}?w=128&h=128&fit=crop&auto=compress` : `/images/logos/acronym.png`}/>}
                {!deferImage && <img width={128} height={128} alt={author.name}
                                          src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto.url}?w=128&h=128&fit=crop&auto=compress` : `/images/logos/acronym.png`}/>}
            </div>
            <div className="author-card__body">
                <div className="name-container">
                    <h2 className="author-card__title">{author.name}</h2>
                    <div className="social-container">
                        {author.linkedin && <a href={author.linkedin} target="_blank" rel="noopener noreferrer">
                            <LinkedinIcon iconFillColor={'white'} size={32} round={true}/>
                        </a>}
                        {author.email && <a href={`mailto:${author.email}`} target="_blank" rel="noopener noreferrer">
                            <EmailIcon iconFillColor={'white'} size={32} round={true}/>
                        </a>}
                        {author.twitter && <a href={author.twitter} target="_blank" rel="noopener noreferrer">
                            <TwitterIcon iconFillColor={'white'} size={32} round={true}/>
                        </a>}
                    </div>
                </div>
                <div className="author-bio" dangerouslySetInnerHTML={{__html: author.bio}}/>
            </div>
        </div>
    )
}

export default AuthorDetails
